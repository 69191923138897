import en from '@/translate/languages/en.json'
import fr from '@/translate/languages/fr.json'
import it from '@/translate/languages/it.json'
import pt from '@/translate/languages/pt.json'
import esES from '@/translate/languages/es-ES.json'
import esCO from '@/translate/languages/es-CO.json'
import esPE from '@/translate/languages/es-PE.json'
import esPY from '@/translate/languages/es-PY.json'
import esCL from '@/translate/languages/es-CL.json'
import esAR from '@/translate/languages/es-AR.json'
import esMX from '@/translate/languages/es-MX.json'
import esSV from '@/translate/languages/es-SV.json'
import esGT from '@/translate/languages/es-GT.json'

const resources = {
  en: { translation: en },
  fr: { translation: fr },
  it: { translation: it },
  pt: { translation: pt },
  'es-ES': { translation: esES },
  'es-CO': { translation: esCO },
  'es-PE': { translation: esPE },
  'es-PY': { translation: esPY },
  'es-CL': { translation: esCL },
  'es-AR': { translation: esAR },
  'es-MX': { translation: esMX },
  'es-SV': { translation: esSV },
  'es-GT': { translation: esGT },
}

export default resources