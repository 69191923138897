import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import sendEvent from '@/utils/sendEvent'
import useWorker from '@/hooks/useWorker'
import useAuth from '@/hooks/useAuth'
import { toastIDs } from '@/utils/toastIDs'

const useCometchatLogin = () => {
  const navigate = useNavigate()
  const { worker } = useWorker()
  const login = useAuth((state) => state.login)

  return useQuery({
    queryKey: ['cometchat', 'login', worker],
    queryFn: async () => {
      if (!worker) return

      return sendEvent(worker, {
        type: 'COMET_CHAT_LOGIN',
        payload: {
          loginUrl: `${process.env.API_ENDPOINT}/login`,
        },
      })
    },
    onSuccess: (authInfo) => {
      if (!authInfo.user || !authInfo.appToken) return

      localStorage.setItem('userLanguage', authInfo.user.language)

      login(authInfo)
      navigate('/normative')
    },
    onError: () => {
      toast.error(
        'No se ha logrado registrar en la aplicación, intente de nuevo mas tarde o contacte un administrador',
        {
          id: toastIDs.errorUserRegister,
        }
      )
      navigate('/')
    },
  })
}

export default useCometchatLogin
