export default (worker, data) =>
  new Promise((res, rej) => {
    const channel = new MessageChannel()

    channel.port1.onmessage = ({ data: portData }) => {
      channel.port1.close()

      if (portData.error) {
        rej(portData.error)
      } else {
        res(portData.result)
      }
    }

    data = JSON.parse(JSON.stringify(data))
    worker.active.postMessage([data], [channel.port2])
  })
