import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import useWorker from '@/hooks/useWorker'
import sendEvent from '@/utils/sendEvent'
import useAuth from '@/hooks/useAuth'
import { toastIDs } from '@/utils/toastIDs'

const LOGOUT_COGNITO_URL = `${process.env.COGNITO_URI}/logout?client_id=${process.env.CLIENT_ID}&logout_uri=${window.location.origin}/&identity_provider=${process.env.IDENTITY_PROVIDER}&response_type=code`

const useCometchatLogout = () => {
  const { worker } = useWorker()
  const navigate = useNavigate()
  const logout = useAuth((state) => state.logout)

  return useQuery({
    queryKey: ['cometchat', 'logout', worker],
    queryFn: async () => {
      if (!worker) return false

      await sendEvent(worker, {
        type: 'SIGN_OUT',
        payload: {},
      })
      return true
    },
    onSuccess: (res) => {
      if (!res) return

      logout()
      window.location.assign(LOGOUT_COGNITO_URL)
    },
    onError: (err) => {
      toast.error(`Ha fallado la salida de la aplicación: ${err.message}`, {
        id: toastIDs.errorExitApp,
      })
      navigate('/')
    },
  })
}

export default useCometchatLogout
