import { styled } from '@mui/material'
import Check from '@/assets/icons/Check'
import Info from '@/assets/icons/Info'
import Error from '@/assets/icons/Error'
import { Toaster } from 'react-hot-toast'
import { theme } from '@konecta/styleguide'

const StyledCheck = styled(Check)({
  color: '#ffffff',
  width: '4.183rem',
})

const StyledInfo = styled(Info)({
  color: '#ffffff',
  width: '4.183rem',
})

const StyledError = styled(Error)({
  color: '#ffffff',
  width: '4.183rem',
})

const ToasterCustom = () => {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        style: { fontFamily: theme.typography.fontFamily },
        // Ok
        success: {
          icon: <StyledCheck />,
          style: {
            backgroundColor: '#52A263',
            color: '#ffffff',
          },
        },
        // Info
        loading: {
          icon: <StyledInfo />,
          style: {
            backgroundColor: '#005398',
            color: '#ffffff',
          },
        },
        // Error
        error: {
          icon: <StyledError />,
          style: {
            backgroundColor: '#BF4240',
            color: '#ffffff',
          },
        },
      }}
    />
  )
}

export default ToasterCustom
