import { useMutation } from '@tanstack/react-query'
import { useSearchParams, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import sendEvent from '@/utils/sendEvent'
import { toastIDs } from '@/utils/toastIDs'

const useCognitoLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  return useMutation({
    mutationKey: ['cognito', 'login'],
    mutationFn: ({ worker, code }) =>
      sendEvent(worker, {
        type: 'COGNITO_LOGIN',
        payload: {
          cognitoURL: process.env.COGNITO_URI,
          clientId: process.env.CLIENT_ID,
          code,
          redirectUri: `${window.location.origin}/`,
          identityProvider: process.env.IDENTITY_PROVIDER,
          state: searchParams.get('state') || ''
        },
      }),
    onSuccess: () => {
      navigate('/login', { state: { from: '/' } })
    },
    onError: () => {
      toast.error(
        'Ha fallado el ingreso a la aplicación, intenta de nuevo mas tarde o contacta un administrador',
        {
          id: toastIDs.errorLogin,
        }
      )
      searchParams.delete('code')
      searchParams.delete('state')
      setSearchParams(searchParams)
    },
  })
}

export default useCognitoLogin
