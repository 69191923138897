import { Backdrop, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const FullScreenCircularProgress = ({
  onClick = () => {
    // Intentional
  },
  open = true,
  color = 'secondary',
  ...props
}) => {
  return (
    <Backdrop
      open={open}
      onClick={onClick}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      data-testid="backdrop"
      {...props}
    >
      <CircularProgress color={color} data-testid="progressbar" />
    </Backdrop>
  )
}

FullScreenCircularProgress.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
}

export default FullScreenCircularProgress
