import { StrictMode } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ToasterCustom from './components/ToasterCustom'
import { ThemeProvider } from '@mui/material'
import { theme } from '@konecta/styleguide'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Root from '@/routes/Root'
import Login from '@/routes/Login'
import Logout from '@/routes/Logout'
import Guard from '@/components/Guard'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Guard>Redirigiendo...</Guard>,
  },
  {
    path: 'login',
    element: (
      <Guard>
        <Login />
      </Guard>
    ),
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: '*',
    element: <Guard />,
  },
])

const queryClient = new QueryClient()

const App = ({ nonce }) => {
  const cache = createCache({
    key: 'login-mf-styles',
    nonce: nonce,
    prepend: true,
    speedy: false,
    container: document.head
  });

  return (
    <StrictMode>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ToasterCustom />
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </StrictMode>
  )
}

export default App
