export const toastIDs = {
  errorAddCollaborators: 'errorAddCollaborators',
  errorAddFavoriteConversation: 'errorAddFavoriteConversation',
  errorAddNewCollaboratosPermits: 'errorAddNewCollaboratosPermits',
  errorApp: 'errorApp',
  errorBanMembers: 'errorBanMembers',
  errorChangeNotification: 'errorChangeNotification',
  errorCountFilters: 'errorCountFilters',
  errorCreateChannel: 'errorCreateChannel',
  errorCreateGroup: 'errorCreateGroup',
  errorDeleteGroup: 'errorDeleteGroup',
  errorDeleteMessage: 'errorDeleteMessage',
  errorExitApp: 'errorExitApp',
  errorGetBannedMessages: 'errorGetBannedMessages',
  errorGetChannelsCollaborators: 'errorGetChannelsCollaborators',
  errorGetConversationMessages: 'errorGetConversationMessages',
  errorGetCorpChannels: 'errorGetCorpChannels',
  errorGetCrossChannels: 'errorGetCrossChannels',
  errorGetGroupMembers: 'errorGetGroupMembers',
  errorGetGroups: 'errorGetGroups',
  errorGetNewUsersSuggestions: 'ErrorGetNewUsersSuggestions',
  errorGetPinnedConversations: 'errorGetPinnedConversations',
  errorGetSuggestionsNewCollaborators: 'errorGetSuggestionsNewCollaborators',
  errorGetUsers: 'errorGetUsers',
  errorGroupConversations: 'errorGroupConversations',
  errorInputState: 'errorInputState',
  errorJoinCrossChannel: 'errorJoinCrossChannel',
  errorLexical: 'errorLexical',
  errorLogin: 'errorLogin',
  errorMessageRead: 'errorMessageRead',
  errorMessageSolved: 'errorMessageSolved',
  errorModalEditGroupComponent: 'errorModalEditGroupComponent',
  errorModifyGroup: 'errorModifyGroup',
  errorPrivateConversationPermits: 'errorPrivateConversationPermits',
  errorPrivateConversations: 'errorPrivateConversations',
  errorReactionMessage: 'errorReactionMessage',
  errorReadMessage: 'errorReadMessage',
  errorRootLogin: 'errorRootLogin',
  errorSelectProfile: 'errorSelectProfile',
  errorSendChannelPost: 'errorSendChannelPost',
  errorSendMessage: 'errorSendMessage',
  errorSolveMessage: 'errorSolveMessage',
  errorStartApp: 'errorStartApp',
  errorSuggestionsNewUsers: 'errorSuggestionsNewUsers',
  errorUnbanMembers: 'errorUnbanMembers',
  errorUnexpected: 'errorUnexpected',
  errorUnjoinChannel: 'errorUnjoinChannel',
  errorUpdateLanguage: 'errorUpdateLanguage',
  errorUseFilterDataChannels: 'errorUseFilterDataChannels',
  errorUserRegister: 'errorUserRegister',
  errorValidateChannel: 'errorValidateChannel',
  infoMessageRead: 'infoMessageRead',
  successAddNewCollaborators: 'successAddNewCollaborators',
  successGroupDeleted: 'successGroupDeleted',
  successUnjoinChannel: 'successUnjoinChannel',
  successUpdateLanguage: 'successUpdateLanguage',
}
