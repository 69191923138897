import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@mui/material'
import useCognitoLogin from '@/hooks/useCognitoLogin'
import useWorker from '@/hooks/useWorker'

const CognitoRedirect = ({ code }) => {
  const { worker } = useWorker()
  const { mutate, isLoading } = useCognitoLogin(code, worker)

  useEffect(() => {
    if (isLoading || !worker) return

    mutate({ worker, code })
  }, [isLoading, worker, mutate, code])

  return <CircularProgress aria-label="Validando código" />
}

CognitoRedirect.propTypes = {
  code: PropTypes.string.isRequired,
}

export default CognitoRedirect
