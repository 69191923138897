import FullScreenCircularProgress from '@/components/FullScreenCircularProgress'
import useCometchatLogout from '@/hooks/useCometchatLogout'

const Logout = () => {
  useCometchatLogout()

  return <FullScreenCircularProgress arial-label="Saliendo de la aplicación" />
}

export default Logout
