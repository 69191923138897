import { Box } from '@mui/material'

export default (props) => {
  return (
    <Box {...props} aria-label="Konecta">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 75 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0368 1.39439H11.5232V0H8.98924L5.92441 5.48556H4.49462V0H0V1.39439H1.71999V13.2503H4.49462V6.9295H5.49972L9.13081 13.2503H12.4292L7.84258 5.20951L10.0368 1.39439Z"
          fill="#182D4D"
        />
        <path
          d="M17.9714 11.9052C15.6285 11.9052 15.2463 10.0437 15.1968 7.72203C15.0977 5.35086 15.6285 3.25573 17.9714 3.25573C20.3143 3.25573 20.6965 5.25884 20.6965 7.58047C20.647 9.95164 20.3143 11.9052 17.9714 11.9052ZM17.9219 1.90381C13.7599 1.90381 12.1886 5.39333 12.1886 7.67249C12.1886 9.95164 13.8165 13.2996 17.9219 13.2996C22.0838 13.2996 23.5632 10.1357 23.5632 7.62294C23.5632 5.35086 22.0838 1.90381 17.9219 1.90381Z"
          fill="#182D4D"
        />
        <path
          d="M31.5968 1.86133C30.0184 1.86133 28.7797 2.56206 27.9657 3.67333V1.86133H23.28V3.21325H25.1911V13.2571H27.9657V6.92927C27.9657 4.97571 28.9708 3.25572 30.8323 3.25572C32.1206 3.25572 32.3612 4.18296 32.3612 5.39332V13.2996H35.1359V5.25175C35.0863 2.97259 33.8901 1.86133 31.5968 1.86133Z"
          fill="#182D4D"
        />
        <path
          d="M62.5212 0L59.7466 0.927236V1.85447H58.2177V3.25594H59.7466V10.511C59.7466 13.1158 61.7072 13.5829 64.0501 13.0662C64.3403 13.0167 64.673 12.8327 64.673 12.8327V11.6718C63.859 11.9974 62.4717 12.0895 62.5212 10.419V3.25594H65.0056V1.86155H62.5212V0Z"
          fill="#182D4D"
        />
        <path
          d="M41.5416 3.25598C42.8794 3.25598 43.4527 4.23276 43.5022 5.48559H39.5314C39.581 4.23276 40.1048 3.25598 41.5416 3.25598ZM45.3638 9.85988C44.7409 11.3958 43.5447 11.955 42.0158 11.955C40.3454 11.955 39.581 10.327 39.581 8.37347V7.58072V6.97908H46.7511V6.7455C46.56 3.81515 44.5993 1.91113 41.7823 1.91113C38.9156 1.91113 36.4312 4.55836 36.4312 7.63027C36.4312 10.6031 39.0147 13.3069 41.8814 13.3069C44.0331 13.3069 45.8026 11.9125 46.4255 9.86696H45.3638V9.85988Z"
          fill="#182D4D"
        />
        <path
          d="M56.7384 9.85963C56.1156 11.3956 54.9194 11.9052 53.3905 11.9052C51.72 11.9052 50.4743 9.9021 50.5238 7.30442C50.6229 4.33161 51.9111 3.25573 53.8222 3.25573C54.3956 3.25573 54.9689 3.16372 56.1651 3.72289V5.49242H57.5524V3.25573C56.7384 2.46298 55.5422 1.90381 53.4896 1.90381C50.1911 1.90381 47.7563 4.60058 47.7563 7.62294C47.7563 10.5958 50.3398 13.2996 53.2064 13.2996C55.3582 13.2996 57.1277 11.9052 57.7506 9.85963H56.7384Z"
          fill="#182D4D"
        />
        <path
          d="M72.2253 7.48846C72.2253 10.4613 71.1707 11.9052 69.3091 11.9052C68.3536 11.9052 67.8723 11.4381 67.8723 10.0932C67.8723 7.16287 72.1758 6.97884 72.1758 6.97884L72.2253 7.48846ZM74.9504 5.48535C74.9504 3.58133 74.0869 1.90381 70.5549 1.90381C69.3091 1.90381 67.8794 2.13739 66.8247 3.25573C66.4921 3.63088 66.0603 4.4661 66.0603 5.48535H67.4476C67.4476 5.30132 67.4476 5.15976 67.4971 5.01819C67.9785 3.2982 69.6984 3.24866 70.3638 3.2982C71.3193 3.34775 72.2253 3.85738 72.2253 5.4358V5.99498C70.3142 6.08699 65.0552 6.73818 65.0552 10.1782C65.0552 12.1317 66.0603 13.2925 68.4456 13.2925C69.5427 13.2925 71.4538 12.7829 72.2253 11.9406V13.2925H74.9999L74.9504 5.48535Z"
          fill="#182D4D"
        />
      </svg>
    </Box>
  )
}
