import create from 'zustand'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { toastIDs } from '@/utils/toastIDs'

const useStore = create((set) => ({
  worker: null,
  setWorker: (worker) => set({ worker }),
}))

/**
 * @returns {{worker: ServiceWorkerRegistration | null}}
 */
const useWorker = () => {
  const setWorker = useStore((state) => state.setWorker)
  const worker = useStore((state) => state.worker)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    navigator.serviceWorker.ready
      .then((w) => {
        if (!signal.aborted) setWorker(w)
      })
      .catch((err) => {
        if (!signal.aborted)
          toast.error(
            `No se ha logrado inicializar la aplicación: ${err.message}`,
            {
              id: toastIDs.errorStartApp,
            }
          )
      })

    return () => {
      controller.abort()
    }
  }, [setWorker])

  return {
    worker,
  }
}

export default useWorker
