import FullScreenCircularProgress from '@/components/FullScreenCircularProgress'
import useCometchatLogin from '@/hooks/useCometchatLogin'

const Login = () => {
  useCometchatLogin()

  return <FullScreenCircularProgress aria-label="Cargando aplicación" />
}

export default Login
