import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Box, Button, Typography, styled } from '@mui/material'
import Splash from '@/assets/Splash'
import Konecta from '@/assets/Konecta'
import Onechat from '@/assets/Onechat.jsx'
import CognitoRedirect from '@/components/CognitoRedirect'
import { toastIDs } from '@/utils/toastIDs'

const RootStyle = styled('div')({
  height: '100vh',
  width: '100vw',
  minHeight: 420,
})

const ContentStyle = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
})

const SplashImage = styled(Splash)(({ theme }) => ({
  maxHeight: 300,
  minHeight: 150,
  height: '25vh',
  margin: theme.spacing(4, 0),
}))

const OnechatImage = styled(Onechat)({
  maxHeight: 150,
  minHeight: 60,
  height: '13vh',
})

const FooterSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: theme.spacing(2, 0),
}))

const KonectaLogo = styled(Konecta)(({ theme }) => ({
  height: 30,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: 20,
  },
}))

const UpperSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  minHeight: 300,
  padding: theme.spacing(1, 2),
}))

const GoButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: 300,
  borderRadius: theme.spacing(4),
  backgroundColor: '#FCB90D',
  color: 'white',
  margin: theme.spacing(0, 2),
  '&:hover': {
    backgroundColor: '#FCB90D',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 180,
  },
}))

const CopyrightStyle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary.darker,
}))

const LOGIN_URL = `${process.env.COGNITO_URI}/oauth2/authorize?identity_provider=${process.env.IDENTITY_PROVIDER}&redirect_uri=${window.location.origin}/&response_type=${process.env.RESPONSE_TYPE}&client_id=${process.env.CLIENT_ID}&scope=${process.env.COGNITO_SCOPE}`
const DATE = new Date()
const YEAR = DATE.getFullYear()

const Root = () => {
  const [searchParams] = useSearchParams()
  const codeParam = searchParams.get('code'),
    error = searchParams.get('error'),
    errorDescription = searchParams.get('error_description')

  const handleRedirectToCognito = () => {
    window.location.assign(LOGIN_URL)
  }

  useEffect(() => {
    if (error || errorDescription) {
      toast.error(
        `Ha ocurrido un error al entrar: ${error} - ${errorDescription}`,
        {
          id: toastIDs.errorRootLogin,
        }
      )
    }
  }, [error, errorDescription])

  return (
    <RootStyle>
      <ContentStyle>
        <UpperSection>
          <OnechatImage />
          <SplashImage />
          {codeParam ? (
            <CognitoRedirect code={codeParam} />
          ) : (
            <GoButton
              size="large"
              variant="contained"
              color="inherit"
              onClick={handleRedirectToCognito}
            >
              GO!
            </GoButton>
          )}
        </UpperSection>
        <FooterSection>
          <KonectaLogo />
          <CopyrightStyle variant="caption">
            Copyright ©{YEAR} por Konecta. Todos los derechos reservados.
          </CopyrightStyle>
        </FooterSection>
      </ContentStyle>
    </RootStyle>
  )
}

export default Root
