import { Navigate, useLocation } from 'react-router-dom'

import useAuth from '@/hooks/useAuth'
import FullScreenCircularProgress from '../FullScreenCircularProgress'

const Guard = ({ children }) => {
  const location = useLocation()
  let user = useAuth((state) => state.user)
  const isLoading = useAuth((state) => state.isLoading)
  const channelUser = useAuth((state) => state.channelUser)
  if (channelUser) user = channelUser

  if(isLoading) {
    return <FullScreenCircularProgress />
  }

  if (!user && location.pathname !== '/' && location.state?.from !== '/')
    return <Navigate to="/" state={{ from: location }} replace />

  return children
}

export default Guard
