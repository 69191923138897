import { Box } from '@mui/material'

export default (props) => {
  return (
    <Box {...props} aria-label="Onechat">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 280 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.159 25.4736C108.683 25.4736 111.049 29.4754 111.246 34.6191H94.8749C95.0717 29.4754 97.241 25.4736 103.159 25.4736ZM118.939 52.5246C116.377 58.8102 111.443 61.0991 105.131 61.0991C98.2298 61.0991 95.0717 54.4328 95.0717 46.4292V40.7145H124.66V39.7629C123.873 27.7625 115.786 19.9492 104.148 19.9492C92.5088 19.9492 82.0557 30.8077 82.0557 43.379C82.0557 55.9504 92.7056 66.6185 104.541 66.6185C113.415 66.6185 120.715 60.9038 123.278 52.5196H118.939V52.5246Z"
          fill="url(#paint0_linear_2252_11628)"
        />
        <path
          d="M62.1287 19.7549C55.6207 19.7549 50.49 22.6147 47.1401 27.1825V19.7549H29.8157V20.7315C27.9339 20.2858 25.8756 20.0404 23.6256 20.0404C6.49791 20.0404 0 34.2996 0 43.6154C0 52.9313 6.69466 66.6195 23.6256 66.6195C29.5836 66.6195 34.2099 65.0518 37.706 62.5776C37.706 62.5776 46.8425 55.6759 46.8425 40.5202C46.8425 32.5166 51.282 25.4696 58.9756 25.4696C64.3031 25.4696 65.2868 29.2811 65.2868 34.2345V66.6195H76.7288V33.6635C76.532 24.3277 71.5981 19.7549 62.1337 19.7549H62.1287ZM23.8223 60.9198C14.1763 60.9198 12.6023 53.3169 12.4056 43.8057C12.0121 34.1093 14.1763 25.5547 23.8223 25.5547C33.4683 25.5547 35.0423 33.7286 35.0423 43.2348C34.8455 52.9313 33.4683 60.9148 23.8223 60.9148V60.9198Z"
          fill="url(#paint1_linear_2252_11628)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M30.4524 21.5871C27.2741 18.9075 18.8439 19.759 17.6937 24.5571L16.0187 27.3669C15.5193 28.2033 15.1561 29.0898 14.9189 30.0064C16.6796 27.2317 19.4998 25.5087 23.8233 25.5087C33.4693 25.5087 35.0433 33.6826 35.0433 43.1888C35.013 44.6312 34.9575 46.0386 34.8516 47.3859C38.1913 47.8717 40.9863 47.5963 42.0104 45.8783L46.7678 42.8131L47.2016 30.5373L30.4524 21.5871Z"
          fill="url(#paint2_linear_2252_11628)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M38.8109 61.5855C34.4218 64.5756 33.9375 52.0794 34.2503 51.9541L46.671 44.4814L46.2321 46.9707C46.2321 46.9707 45.1424 52.0894 43.8509 54.5035C42.8267 56.4167 40.6978 60.2983 38.816 61.5805L38.8109 61.5855Z"
          fill="url(#paint3_linear_2252_11628)"
        />
        <path
          d="M47.2004 32.992V8.35015C47.2004 5.19979 44.9705 3.92262 42.221 5.4953L25.0127 15.3621C23.4841 16.2385 23.4841 17.656 25.0127 18.5324L44.0927 29.471C46.4437 30.7732 46.8119 32.0254 47.2004 32.992Z"
          fill="#236FB5"
        />
        <path
          d="M33.6914 33.4824V50.8319C33.6914 52.2894 34.7206 52.8804 35.997 52.1541L44.2404 47.0655C47.6912 45.0871 47.6912 41.8817 44.2404 39.9033L33.6965 33.4824H33.6914Z"
          fill="#236FB5"
        />
        <path
          d="M165.495 59.7823C163.144 62.2965 160.859 64.0445 158.639 65.0262C156.419 66.0078 153.695 66.4987 150.481 66.4987C144.488 66.4987 139.7 64.5654 136.113 60.7038C132.219 56.5968 130.276 50.797 130.276 43.3143C130.276 35.8316 132.224 30.0317 136.113 25.9247C139.695 22.0582 144.483 20.1299 150.481 20.1299C153.695 20.1299 156.414 20.6207 158.639 21.6024C160.864 22.5841 163.149 24.332 165.495 26.8463L160.955 31.0785C157.862 27.7078 154.376 26.0149 150.481 26.0149C145.906 26.0149 142.384 27.7328 139.917 31.1686C137.94 33.9283 136.951 37.9752 136.951 43.3143C136.951 48.6533 137.94 52.7002 139.917 55.4599C142.389 58.8957 145.911 60.6137 150.481 60.6137C154.311 60.6137 157.802 58.8957 160.955 55.4599L165.495 59.7823Z"
          fill="#1D2D50"
        />
        <path
          d="M208.978 65.9478H202.304V37.9753C202.304 30.0018 198.596 26.015 191.179 26.015C187.658 26.015 184.878 27.0417 182.84 29.0952C180.802 31.1487 179.783 34.1087 179.783 37.9753V65.9478H173.108V0.436523H179.783V25.6494C183.057 21.9681 187.32 20.13 192.572 20.13C197.824 20.13 201.713 21.6325 204.619 24.6376C207.525 27.6427 208.973 31.7247 208.973 36.8734V65.9478H208.978Z"
          fill="#1D2D50"
        />
        <path
          d="M250.887 65.9477H244.212V61.6254C240.938 64.8759 236.549 66.5037 231.05 66.5037C225.177 66.5037 220.884 65.1213 218.165 62.3616C215.88 60.0327 214.734 56.9625 214.734 53.161C214.734 49.1141 216.107 45.9237 218.856 43.5897C221.606 41.2608 225.485 40.0938 230.49 40.0938H244.207V35.7715C244.207 29.1452 240.312 25.8346 232.528 25.8346C227.337 25.8346 223.508 27.5525 221.036 30.9883L216.495 26.8463C218.473 24.332 220.758 22.554 223.351 21.5122C225.697 20.5907 228.79 20.1299 232.619 20.1299C244.792 20.1299 250.876 25.1584 250.876 35.2205V65.9527L250.887 65.9477ZM244.212 49.9355V45.0572H231.423C224.628 45.0572 221.227 47.7268 221.227 53.0608C221.227 58.3949 224.718 60.789 231.701 60.789C236.397 60.789 239.793 59.7772 241.896 57.7538C243.44 56.2813 244.212 53.6769 244.212 49.9305V49.9355Z"
          fill="#1D2D50"
        />
        <path
          d="M280 65.9476H275.273C271.504 65.9476 268.598 64.7506 266.56 62.3615C264.583 60.2129 263.594 57.363 263.594 53.802V26.0148H257.848V20.9562H263.594V6.78711H270.268V20.9562H280V26.0148H270.268V53.6167C270.268 58.0342 272.337 60.2429 276.479 60.2429H280V65.9476Z"
          fill="#1D2D50"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2252_11628"
            x1="77.2075"
            y1="16.2029"
            x2="108.926"
            y2="48.1467"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.09" stopColor="#182D4D" />
            <stop offset="0.48" stopColor="#05528C" />
            <stop offset="0.88" stopColor="#182D4D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2252_11628"
            x1="15.8059"
            y1="17.7765"
            x2="70.4673"
            y2="72.8358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#182D4D" />
            <stop offset="0.17" stopColor="#162F51" />
            <stop offset="0.37" stopColor="#12375F" />
            <stop offset="0.58" stopColor="#0B4475" />
            <stop offset="0.74" stopColor="#05528C" />
            <stop offset="0.94" stopColor="#182D4D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2252_11628"
            x1="38.3982"
            y1="24.7725"
            x2="25.5274"
            y2="43.0365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6F6F6E" />
            <stop offset="0.02" stopColor="#7C7C7B" />
            <stop offset="0.08" stopColor="#A3A3A3" />
            <stop offset="0.15" stopColor="#C4C4C4" />
            <stop offset="0.22" stopColor="#DEDEDE" />
            <stop offset="0.29" stopColor="#F0F0F0" />
            <stop offset="0.36" stopColor="#FBFBFB" />
            <stop offset="0.44" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2252_11628"
            x1="33.7869"
            y1="48.749"
            x2="38.9734"
            y2="59.5101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BABABA" />
            <stop offset="0.03" stopColor="#C4C4C4" />
            <stop offset="0.13" stopColor="#DEDEDE" />
            <stop offset="0.23" stopColor="#F0F0F0" />
            <stop offset="0.33" stopColor="#FBFBFB" />
            <stop offset="0.44" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}
