import { Box } from '@mui/material'

export default (props) => {
  return (
    <Box {...props} aria-label="Konecta">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%" viewBox="0 0 450 82"
        preserveAspectRatio="xMidYMid meet" fill="#2800c8">
        <g transform="translate(0,82) scale(0.1,-0.1)"
          fill="#2800c8" stroke="none">
          <path d="M50 405 l0 -355 70 0 70 0 0 55 c0 49 4 60 33 91 l33 36 77 -91 77
-91 91 0 c69 0 88 3 83 13 -6 9 -149 183 -222 269 -13 16 -10 23 35 75 27 32
79 95 117 141 l68 82 -94 0 -94 0 -94 -115 c-51 -63 -97 -115 -102 -115 -4 0
-8 81 -8 180 l0 180 -70 0 -70 0 0 -355z"/>
          <path d="M3512 503 c3 -282 3 -280 70 -365 37 -47 135 -96 211 -104 l57 -7 0
77 0 76 -44 0 c-51 0 -99 29 -133 79 -20 30 -23 46 -23 137 l0 104 70 0 70 0
0 75 0 75 -70 0 -70 0 0 55 0 55 -71 0 -70 0 3 -257z"/>
          <path d="M845 639 c-70 -9 -124 -39 -178 -99 -53 -59 -77 -121 -77 -202 0
-120 61 -217 168 -270 57 -28 77 -33 137 -33 166 1 297 132 299 300 3 189
-158 329 -349 304z m123 -159 c18 -11 41 -34 52 -52 44 -73 35 -150 -25 -208
-30 -29 -43 -34 -92 -37 -67 -5 -110 16 -144 69 -27 45 -23 140 9 185 43 61
137 81 200 43z"/>
          <path d="M3132 638 c-233 -35 -333 -325 -172 -501 125 -137 329 -137 450 0 22
26 37 50 33 54 -5 4 -35 18 -68 32 l-60 25 -37 -34 c-33 -30 -45 -34 -91 -34
-65 0 -109 25 -137 79 -56 111 16 241 135 241 41 0 69 -12 106 -47 l26 -24 67
27 c36 15 66 30 66 34 0 18 -74 89 -119 113 -61 34 -128 45 -199 35z"/>
          <path d="M4111 638 c-143 -21 -251 -152 -251 -304 0 -115 69 -222 175 -270 90
-42 136 -41 274 2 17 5 22 4 17 -4 -5 -9 14 -12 68 -12 l76 0 0 290 0 290 -75
0 c-41 0 -74 -3 -74 -7 3 -21 -4 -22 -32 -8 -44 23 -115 32 -178 23z m126
-155 c108 -51 115 -211 12 -281 -28 -20 -43 -23 -93 -20 -54 4 -62 8 -97 45
-52 56 -61 114 -28 180 42 85 124 115 206 76z"/>
          <path d="M1465 621 c-16 -10 -38 -27 -48 -39 -16 -17 -220 -483 -214 -488 1
-1 34 -15 73 -32 l70 -29 74 176 c125 296 119 286 134 270 8 -8 45 -90 84
-182 80 -192 91 -211 135 -237 44 -27 86 -34 135 -20 66 17 101 61 159 201 28
68 72 173 97 232 l46 108 -66 29 c-36 17 -69 30 -74 30 -4 0 -44 -89 -90 -197
-92 -221 -107 -253 -118 -253 -4 0 -37 69 -72 153 -91 217 -105 243 -147 272
-47 32 -132 35 -178 6z"/>
          <path d="M2394 616 c-176 -82 -232 -302 -118 -460 79 -108 235 -154 353 -103
46 20 109 66 130 95 l20 29 -66 27 -66 27 -31 -22 c-45 -34 -133 -38 -179 -10
-31 19 -67 60 -67 76 0 3 101 5 225 5 l225 0 0 60 c0 119 -67 226 -175 276
-70 33 -180 33 -251 0z m195 -131 c16 -8 41 -29 56 -47 l28 -33 -76 -3 c-43
-2 -111 -2 -153 0 l-76 3 23 29 c49 62 134 85 198 51z"/>
        </g>
      </svg>
    </Box>
  )
}
